// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("channels")
require('jquery')
require('@fancyapps/fancybox')
require("site/jquery.easytabs.min")
require("site/jquery.jcarousel.min")
require("site/jcarousel")
require("site/jquery.slides.min")
require("site/html5video")
require("site/posm")

import '../../assets/stylesheets/menu';
import '../../assets/stylesheets/site/all_saved';
import '../../assets/stylesheets/site/design2014';
import '@fancyapps/fancybox/dist/jquery.fancybox.css';
import '../../assets/stylesheets/site/posm';
import '../../assets/stylesheets/site/skin';
import '../../assets/stylesheets/site/tovar';
import '../../assets/stylesheets/site/yashare';
import '../../assets/stylesheets/mobile/mobile';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../../assets/images', true)
const imagePath = (name) => images(name, true)
