$(function(){
	$("#slides").slidesjs({
		width: 1000,
		height: 357,
		navigation: {
			active: false,
			effect: "fade"
		},
		effect: {
			fade: {speed: 500}
		},
		play: {
			auto: true,
			effect: "fade",
			interval: 4000
		}
	});
});

/*
$(function(){
    $("#fade_slides").slidesjs({
	width: 1000,
	height: 357,
        container: 'fade_slides_container',
	effect: 'fade, fade',
	crossfade: true,
	slideSpeed: 350,
	fadeSpeed: 500,
	generatePagination: false,
        play:2500
    });
});
*/


$(document).ready(function(){
    $("#easytab-container").easytabs({
        updateHash: false
    });

    if ( $("video").length > 0 ) {
      $("video")[0].volume=0.1;
    }

    $(".easytab-tab.right-tab").click(function(){
      if ( $("#easytab-video video").length > 0 ) {
      $("#easytab-video video")[0].play();
      $("#easytab-video video")[0].muted=false;
      }
    });

    $(".easytab-tab.left-tab").click(function(){
      if ( $("#easytab-video video").length > 0 ) {
      $("#easytab-video video")[0].pause();
      $("#easytab-video video")[0].muted=true;
      }
    });

    $(".read-detail").click(function(){
      $(this).hide();
      $(this).parents('div.detail').find('div.short_description').hide();
      $(this).parents('div.detail').find('div.full_description').show();
    });

   $('.hidden-map').on('click',function(){
        $('#choose-nav').show(300);
   });
   $('#choose-nav .close').on('click',function(){
        $('#choose-nav').hide(300);
   });


});
