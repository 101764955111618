$(document).ready(function(){
    $(".filters .filter").click(function(){
        if ($(this).hasClass('openedfilter')) {
            $(this).find('span').html('Развернуть весь список');
            $(this).parent().find('div.filter-window').slideUp('slow');
            $(this).removeClass('openedfilter').addClass('closedfilter');
        } else {
            $(this).find('span').html('Свернуть весь список');
            $(this).parent().find('div.filter-window').slideDown('slow');
            $(this).removeClass('closedfilter').addClass('openedfilter');
        }
    });

    $(".tabs .right-tab-header, .tabs .left-tab-header").click(function(){
        var lr = "";
        var lr_other = "";
        if ($(this).hasClass('right-tab-header')) {
            lr = "right-tab";
            lr_other = "left-tab";
        } else {
            lr = "left-tab";
            lr_other = "right-tab";
        }
        var class_lr = "div." + lr;
        var class_lr_other = "div." + lr_other;
        var position = $(this).parents('div.tabs').find('div.current-tab').data("for");
        if (!$(this).hasClass(lr+'-header-current') && $(this).parents('div.tabs').find(class_lr+"[data-for='"+position+"']").hasClass(lr)) {
            $(this).parents('div.tabs').find(class_lr_other+'-header').removeClass(lr_other+'-header-current');
            $(this).parents('div.tabs').find(class_lr+'-header').addClass(lr+'-header-current');

            $(this).parents('div.tabs').find('div.current-tab').hide();
            $(this).parents('div.tabs').find('div.current-tab').removeClass('current-tab');

            $(this).parents('div.tabs').find(class_lr+"[data-for='"+position+"']").show();
            $(this).parents('div.tabs').find(class_lr+"[data-for='"+position+"']").addClass('current-tab');

            var current_block = $(this).parents('div.tabs').find('div.current-tab');
            if (current_block.next().hasClass(lr)) {
              $(this).parents('div.tabs').find('div.right-arrow.white-right-arrow').removeClass('white-right-arrow');
            } else {
              if (!$(this).parents('div.tabs').find('div.right-arrow').hasClass('white-right-arrow')) {
                $(this).parents('div.tabs').find('div.right-arrow').addClass('white-right-arrow');
              }
            }
            if (current_block.prev().hasClass(lr)) {
              $(this).parents('div.tabs').find('div.left-arrow.white-left-arrow').removeClass('white-left-arrow');
            } else {
              if (!$(this).parents('div.tabs').find('div.left-arrow').hasClass('white-left-arrow')) {
                $(this).parents('div.tabs').find('div.left-arrow').addClass('white-left-arrow');
              }
            }
        }
    });


    $(".tabs .right-arrow, .tabs .left-arrow").click(function(){
        var this_arrow = "";
        var other_arrow = "";
        var lr = "";
        var lr_other = "";
        var current_block = $(this).parents('div.tabs').find('div.current-tab');
        var next_block;
        var next_next_block;
        if ($(this).hasClass('right-arrow')) {
            this_arrow = "right-arrow";
            other_arrow = "left-arrow";
            next_block = current_block.next();
            next_next_block = next_block.next();
        } else {
            this_arrow = "left-arrow";
            other_arrow = "right-arrow";
            next_block = current_block.prev();
            next_next_block = next_block.prev();
        }
        if ($(this).parents('div.tabs').find('div.left-tab-header').hasClass('left-tab-header-current')) {
            lr = "left-tab";
            lr_other = "right-tab";
        } else {
            lr = "right-tab";
            lr_other = "left-tab";
        };
        var class_lr = "div." + lr;
        var class_lr_other = "div." + lr_other;

        if (next_block.hasClass(lr)) {
            current_block.hide();
            current_block.removeClass('current-tab');

            next_block.show();
            next_block.addClass('current-tab');

            var position = next_block.data("for");
            if ($(this).parents('div.tabs').find(class_lr_other+"[data-for='"+position+"']").hasClass(lr_other)) {
              $(this).parents('div.tabs').find(class_lr_other+'-header').removeClass('light-'+lr_other+'-header');
              $(this).parents('div.tabs').find(class_lr_other+'-header span').show();
            } else {
              $(this).parents('div.tabs').find(class_lr_other+'-header').addClass('light-'+lr_other+'-header');
              $(this).parents('div.tabs').find(class_lr_other+'-header span').hide();
            }

            $(this).parents('div.tabs').find('div.'+other_arrow+'.white-'+other_arrow).removeClass('white-'+other_arrow);
            if (!next_next_block.hasClass(lr)) {
              if (!$(this).hasClass('white-'+this_arrow)) {
                $(this).addClass('white-'+this_arrow);
              }
            }
        }
    });


  var hide_and_resort_tab_blocks = function() {
              $("div.tab-block").hide();
              $('div.tab.current-tab').hide();
              $('div.tab.current-tab').removeClass('current-tab');
              $('div.tab[data-defclass="current-tab"]').addClass('current-tab');
              $('div.tab.current-tab').show();
              $('div.tab-header.left-tab-header-current').removeClass('left-tab-header-current');
              $('div.tab-header.right-tab-header-current').removeClass('right-tab-header-current');
              $('div.tab-header[data-defclass="left-tab-header-current"]').addClass('left-tab-header-current');
              $('div.tab-header[data-defclass="right-tab-header-current"]').addClass('right-tab-header-current');
              $('div.tab-header.light-left-tab-header').removeClass('light-left-tab-header');
              $('div.tab-header.light-right-tab-header').removeClass('light-right-tab-header');
              $('div.tab-header span').show();
              $('div.tab-header[data-defclass="light-left-tab-header"]').addClass('light-left-tab-header');
              $('div.tab-header[data-defclass="light-right-tab-header"]').addClass('light-right-tab-header');
              $('div.tab-header[data-defclass="light-left-tab-header"] span').hide();
              $('div.tab-header[data-defclass="light-right-tab-header"] span').hide();
              $('div.white-left-arrow').removeClass('white-left-arrow');
              $('div[data-defclass="white-left-arrow"]').addClass('white-left-arrow');
              $('div.white-right-arrow').removeClass('white-right-arrow');
              $('div[data-defclass="white-right-arrow"]').addClass('white-right-arrow');
  }

});
