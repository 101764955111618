(function($) {
    $(function() {
        $('.mycarousel').each(function() {
            var el = $(this);
            el.jcarousel({
            });
        });

        $('.jcarousel-prev-horizontal')
	    .on('jcarouselcontrol:active', function() {
                $(this).removeClass('jcarousel-prev-disabled-horizontal');
            })
            .on('jcarouselcontrol:inactive', function() {
                $(this).addClass('jcarousel-prev-disabled-horizontal');
            })
            .jcarouselControl({
                target: '-=1'
            });

        $('.jcarousel-next-horizontal')
	    .on('jcarouselcontrol:active', function() {
                $(this).removeClass('jcarousel-next-disabled-horizontal');
            })
            .on('jcarouselcontrol:inactive', function() {
                $(this).addClass('jcarousel-next-disabled-horizontal');
            })
            .jcarouselControl({
                target: '+=1'
            });

    });
})(jQuery);
